<template>
  <v-container>
    <v-card v-if="form.isviewform" class="mb-5" flat>
      <v-card-title>会社・代表者情報</v-card-title>
    </v-card>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
          <validation-provider
            v-slot="{ errors }"
            name="会社名"
            rules="required|kanji|max:25"
          >
            <v-text-field
              persistent-placeholder persistent-hint outlined
              v-model.trim="c_company_name_kanji"
              :readonly="!inputable"
              :error-messages="errors"
              required
              label="会社名"
              placeholder="例）株式会社ミスミ"
              hint="全角25文字まで"
            ></v-text-field>
          </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
        <validation-provider
          v-slot="{ errors }"
          name="会社名（カナ）"
          rules="required|kanaall|max:30|kanamax:30"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_company_name_kana"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="会社名（カナ）"
            placeholder="例）カブシキガイシャミスミ"
            hint="カタカナ30文字まで。濁点も1文字としてカウントされます"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
        <validation-provider
          v-slot="{ errors }"
          name="本社郵便番号"
          rules="required|zipcdjp"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_zip_cd"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="本社郵便番号"
            placeholder="例）112-8583"
            messages="半角数字とハイフン記号（-）"
            hint="半角数字とハイフン記号（-）"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
        <validation-provider
          v-slot="{ errors }"
          name="本社住所1"
          rules="required|kanji|max:15"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_address_1_kanji"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="本社住所1"
            placeholder="例）東京都千代田区九段南１－６－５"
            hint="全角15文字まで。入り切らない場合、住所2も使って入力してください"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
        <validation-provider
          v-slot="{ errors }"
          name="本社住所2"
          rules="required|kanji|max:15"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_address_2_kanji"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="本社住所2"
            placeholder="例）九段会館テラス"
            hint="全角15文字まで。入り切らない場合、住所1も使って入力してください"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
        <validation-provider
          v-slot="{ errors }"
          name="本社住所1（カナ）"
          rules="required|kanaall|max:30|kanamax:30"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_address_1_kana"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="本社住所1（カナ）"
            placeholder="例）トウキョウトチヨダククダンミナミ1-6-5"
            hint="カタカナ30文字まで。濁点も1文字としてカウントされます。入り切らない場合、住所2も使って入力してください"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="11">
        <validation-provider
          v-slot="{ errors }"
          name="本社住所2（カナ）"
          rules="required|kanaall|max:30|kanamax:30"
        >
          <v-text-field
            persistent-placeholder persistent-hint outlined
            v-model.trim="c_address_2_kana"
            :readonly="!inputable"
            :error-messages="errors"
            required
            label="本社住所2（カナ）"
            placeholder="例）クダンカイカンテラス"
            hint="カタカナ30文字まで。濁点も1文字としてカウントされます。入り切らない場合、住所1も使って入力してください"
          ></v-text-field>
        </validation-provider>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
          <validation-provider
            v-slot="{ errors }"
            name="代表TEL"
            rules="required|telfaxjp"
          >
            <v-text-field
              persistent-placeholder persistent-hint outlined
              v-model.trim="c_tel"
              :readonly="!inputable"
              :error-messages="errors"
              required
              label="代表TEL"
              placeholder="例）03-5805-7050"
              hint="半角数字とハイフン記号（-）"
            ></v-text-field>
          </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5" align-self="stretch">
            <div style="display: flex;">
              <span style="line-height: 56px; padding: 0 10px; font-size: 24px;">T</span>
              <div style="width: 100%;">
                <validation-provider
                  v-slot="{ errors }"
                  name="インボイス登録番号"
                  rules="required|digits:13"
                >
                  <v-text-field
                    persistent-placeholder persistent-hint outlined
                    v-model.trim="c_invoice_no"
                    :readonly="!inputable"
                    :error-messages="errors"
                    required
                    label="インボイス登録番号"
                    placeholder="1234567890123"
                    hint="半角数字13文字"
                  ></v-text-field>
                </validation-provider>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
                v-slot="{ errors }"
                name="代表者氏名"
                rules="required|kanji|max:20"
            >
              <v-text-field
                  persistent-placeholder persistent-hint outlined
                  v-model.trim="c_representative_name"
                  :readonly="!inputable"
                  :error-messages="errors"
                  required
                  label="代表者氏名"
                  placeholder="例）三住太郎"
                  hint="利用規約への同意権限を有する方。20文字まで"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
                v-slot="{ errors }"
                name="代表者役職"
                rules="required|kanji|max:20"
            >
              <v-text-field
                  persistent-placeholder persistent-hint outlined
                  v-model.trim="c_representative_title"
                  :readonly="!inputable"
                  :error-messages="errors"
                  required
                  label="代表者役職"
                  placeholder="例）代表取締役"
                  hint="20文字まで"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>


        <v-col align="center">
        <v-btn
          class="mr-4"
          color="blue darken-4"
          outlined
          type="submit"
          :disabled="invalid"
          x-large
        >
          {{ nextbtntext() }}
        </v-btn>
        </v-col>
      </form>


      <v-row v-if="!form.isviewform && !form.istermagreed" justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            width="80%"
        >
          <v-card >
            <v-card-actions class="justify-end">
                <v-btn
                    class="mr-2 custom-transform-class text-none"
                    outlined
                    color="indigo"
                    @click="downloadTermsPDF('use-and-rules')"
                >
                  <v-icon class="mr-1">mdi-download</v-icon>
                  meviy partners パートナー利用規約・ルール
                </v-btn>
            </v-card-actions>
            <TermsOfUse />
            <v-divider></v-divider>
            <FeePayment />
            <v-divider></v-divider>
              <v-row justify="center" dense class="ma-0 pa-0 mt-2 mb-6">
          <v-card flat>
              <v-checkbox
                  label="パートナー利用規約、パートナー利用ルールに同意します"
                  type="checkbox"
                  v-model="termscheckbox"
                  value="1"
                  class="ma-0 pa-0"
                  hide-details
              ></v-checkbox>
                </v-card>
            </v-row>
              <v-card-actions class="justify-center">
                <v-btn
                    color="grey"
                    text
                    @click="disagreeTerms"
                    class="mb-10"
                >
                  同意せず戻る
                </v-btn>
                <br/>
                <v-btn
                    color="blue darken-4"
                    outlined
                    @click="agreeTerms"
                    large
                    class="mb-10"
                    :disabled="!termscheckbox"
                >
                  同意して登録申請を進める
                </v-btn>
              </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

    </validation-observer>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as ValidationCustomRules from '@/plugins/vee-validate'
import mepoputil from "@/util/download"
import TermsOfUse from '@/components/TermsOfUse'
import FeePayment from '@/components/FeePayment'
for (const rule in ValidationCustomRules) {
  extend(rule, ValidationCustomRules[rule])
}
import '@mdi/font/css/materialdesignicons.css'

setInteractionMode('lazy')

export default {
  name: 'Supplier',
  title: '会社・代表者情報',
  data: () => ({
    c_company_name_kanji: String,
    c_company_name_kana: String,
    c_zip_cd: String,
    c_address_1_kanji: String,
    c_address_2_kanji: String,
    c_address_1_kana: String,
    c_address_2_kana: String,
    c_tel: String,
    c_fax: String,
    c_invoice_no: String,
    c_representative_name: String,
    c_representative_title: String,
    dialog: false,
    istermagreed: false,
    termscheckbox: false,
  }),
  components: {
    ValidationProvider,
    ValidationObserver,
    FeePayment: FeePayment,
    TermsOfUse: TermsOfUse,
  },
  props: {
    form: PartnerForm,
    inputable: Boolean,
    company_name_kanji: String,
    company_name_kana: String,
    zip_cd: String,
    address_1_kanji: String,
    address_2_kanji: String,
    address_1_kana: String,
    address_2_kana: String,
    tel: String,
    fax: String,
    invoice_no: String,
    representative_name: String,
    representative_title: String,
  },
  computed: {
  },
  methods: {
    submit () {
      this.$refs.observer.validate()
      this.$emit('update:validation_supplier', true)

      this.$emit('update:company_name_kanji', this.c_company_name_kanji)
      this.$emit('update:company_name_kana', ValidationCustomRules.hiraToKana(this.c_company_name_kana))
      this.$emit('update:zip_cd', ValidationCustomRules.addHyphenZipcd(this.c_zip_cd))
      this.$emit('update:address_1_kanji', this.c_address_1_kanji)
      this.$emit('update:address_2_kanji', this.c_address_2_kanji)
      this.$emit('update:address_1_kana', ValidationCustomRules.hiraToKana(this.c_address_1_kana))
      this.$emit('update:address_2_kana', ValidationCustomRules.hiraToKana(this.c_address_2_kana))
      this.$emit('update:tel', this.c_tel)
      this.$emit('update:fax', this.c_fax)
      this.$emit('update:invoice_no', this.c_invoice_no)
      this.$emit('update:representative_name', this.c_representative_name)
      this.$emit('update:representative_title', this.c_representative_title)

      if(this.form.id) {
        this.$emit('confirm')
      } else {
        this.$emit('next')
      }
    },
    nextbtntext() {
      switch (this.form.isviewform) {
        case true:
          return "確認"
        default:
          return "次へ"
      }
    },
    showDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    disagreeTerms() {
      this.$router.push('/')
    },
    async agreeTerms() {
      await this.$emit('agreeTerms')
      this.closeDialog()
    },
    async downloadTermsPDF(type) {
        await mepoputil.downloadTermsPDF(type)
    },
  },
  async created () {
    this.c_company_name_kanji = this.company_name_kanji
    this.c_company_name_kana = this.company_name_kana
    this.c_zip_cd = this.zip_cd
    this.c_address_1_kanji = this.address_1_kanji
    this.c_address_2_kanji = this.address_2_kanji
    this.c_address_1_kana = this.address_1_kana
    this.c_address_2_kana = this.address_2_kana
    this.c_tel = this.tel
    this.c_fax = this.fax
    this.c_invoice_no = this.invoice_no
    this.c_representative_name=this.representative_name
    this.c_representative_title=this.representative_title

  },
  async mounted(){
    await this.$nextTick(function () {
      if (this.form.isviewform == null) {
        switch (this.$route.name) {
          case 'ViewInfo':
            this.$router.push(this.$route.path);
            break
          case 'Registry':
            this.$router.push('/registry')
            break
        }
      } else {
        if (!this.form.isviewform && this.form.id) { // 登録フローかつ登録完了している
          this.$router.push('/viewlist')
        }
      }
      if(!this.form.isviewform && !this.form.istermagreed) {
        this.showDialog()
      }
    })
  },
}
</script>
