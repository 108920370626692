<template>
  <v-container>
    <v-card v-if="form.isviewform" flat>
      <v-card-title>
        内容確認
      </v-card-title>
    </v-card>
    <v-row class="ma-0 pa-0">
      <v-card flat width="60%" class="ml-5">
        <v-chip color="blue-grey lighten-4">パートナー利用規約およびパートナー利用ルールへの同意済み</v-chip>
        <v-btn class="text-decoration-underline" color="blue darken-4" text @click="dialog=true">
          <v-icon class="mr-1">mdi-file-document-multiple-outline </v-icon>
          規約文面を確認する</v-btn>
        <v-card-text class="pa-0 ma-0" align="right">
          <v-btn
              class="custom-transform-class text-none"
              outlined
              color="blue-grey darken-2"
              @click="onDownloadPDFClickWithPDFMake()"
          >
            <v-icon class="mr-1">mdi-download</v-icon>
            登録内容をPDFでダウンロード
          </v-btn>
        </v-card-text>
      </v-card>
    </v-row>
    <validation-observer
      ref="observer"
      v-slot="{ invalid, validated }"
    >
    <form @submit.prevent="submit">
      <v-row>
        <v-card flat width="60%" class="ma-5" outlined rounded>
          <v-card-subtitle>会社・代表者情報</v-card-subtitle>
          <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr><th width="30%">会社名</th><td>{{ form.company_name_kanji }}</td></tr>
              <tr><th>会社名（カナ）</th><td>{{ form.company_name_kana }}</td></tr>
              <tr><th>本社郵便番号</th><td>{{ form.zip_cd }}</td></tr>
              <tr><th>本社住所1</th><td>{{ form.address_1_kanji }}</td></tr>
              <tr><th>本社住所2</th><td>{{ form.address_2_kanji }}</td></tr>
              <tr><th>本社住所1（カナ）</th><td>{{ form.address_1_kana }}</td></tr>
              <tr><th>本社住所2（カナ）</th><td>{{ form.address_2_kana }}</td></tr>
              <tr><th>代表TEL</th><td>{{ form.tel }}</td></tr>
              <tr><th>インボイス登録番号</th><td>{{ invoice_no }}</td></tr>
              <tr><th>代表者氏名</th><td>{{ form.representative_name }}</td></tr>
              <tr><th>代表者役職</th><td>{{ form.representative_title }}</td></tr>
            </tbody>
          </v-simple-table>
          </v-card-text>
        </v-card>
        <v-col lg="4" md="4" sm="4" align-self="center">
          <v-btn v-if="inputable" class="ma-4" color="blue darken-4 outline" outlined @click="$emit('supplier')">
            <v-icon class="mr-1">mdi-pencil-outline </v-icon>
            会社・代表者情報を修正する</v-btn>
        </v-col>
      </v-row>
      <v-row>
          <v-card flat width="60%" class="ma-5" outlined rounded>
            <v-card-subtitle>担当者情報</v-card-subtitle>
            <v-card-text>
              <v-simple-table dense>
                <tbody>
                  <tr><th width="30%">担当者名 姓</th><td>{{ form.person_name_sei_kanji }}</td></tr>
                  <tr><th>担当者名 名</th><td>{{ form.person_name_mei_kanji }}</td></tr>
                  <tr><th>担当者名 姓（カナ）</th><td>{{ form.person_name_sei_kana }}</td></tr>
                  <tr><th>担当者名 名（カナ）</th><td>{{ form.person_name_mei_kana }}</td></tr>
                  <tr><th>部課名</th><td>{{ form.buka_name_kanji }}</td></tr>
                  <tr><th>部課名（カナ）</th><td>{{ form.buka_name_kana }}</td></tr>
                  <tr><th>担当者TEL</th><td>{{ form.person_tel }}</td></tr>
                  <tr><th>担当者FAX</th><td>{{ form.person_fax }}</td></tr>
                  <tr><th>メールアドレス</th><td>{{ form.person_email }}</td></tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        <v-col lg="4" md="4" sm="4" align-self="center">
          <v-btn v-if="inputable" class="ma-4" color="blue darken-4 outline" outlined @click="$emit('person')">
            <v-icon class="mr-1">mdi-pencil-outline </v-icon>
            担当者情報を修正する</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-card flat width="60%" class="ma-5" outlined rounded>
          <v-card-subtitle>銀行口座情報</v-card-subtitle>
          <v-card-text>
          <v-simple-table dense>
            <tbody>
              <tr><th width="30%">銀行コード</th><td>{{ form.bank_code }}</td></tr>
              <tr><th>支店コード</th><td>{{ form.branch_code }}</td></tr>
              <tr><th>銀行名</th><td>{{ form.bank_name }}</td></tr>
              <tr><th>支店名</th><td>{{ form.branch_name }}</td></tr>
              <tr><th>預金種類</th><td>{{ $t(form.account_type) }}</td></tr>
              <tr><th>口座番号</th><td>{{ form.account_number }}</td></tr>
              <tr><th>口座名義</th><td>{{ form.account_name }}</td></tr>
              <tr><th>登録済 添付ファイル</th><td>{{ getFileNames(form.bank_attachment_filename) }}</td></tr>
              <tr><th>新規 添付ファイル</th><td>{{ getFileNames(form.bank_attachment) }}</td></tr>
            </tbody>
          </v-simple-table>
          </v-card-text>
        </v-card>
        <v-col lg="4" md="4" sm="4" align-self="center">
          <v-btn v-if="inputable" class="ma-4" color="blue darken-4 outline" outlined @click="$emit('bank')">
            <v-icon class="mr-1">mdi-pencil-outline </v-icon>
            銀行口座情報を修正する</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-card flat width="60%" class="ma-5" outlined rounded>
          <v-card-subtitle>利用料ご請求先情報</v-card-subtitle>
          <v-card-text>
            <v-simple-table dense>
            <tbody>
              <tr><th width="30%">担当者名 姓</th><td>{{ form.comission_person_name_sei_kanji }}</td></tr>
              <tr><th>担当者名 名</th><td>{{ form.comission_person_name_mei_kanji }}</td></tr>
              <tr><th>担当者名 姓（カナ）</th><td>{{ form.comission_person_name_sei_kana }}</td></tr>
              <tr><th>担当者名 名（カナ）</th><td>{{ form.comission_person_name_mei_kana }}</td></tr>
              <tr><th>担当者TEL</th><td>{{ form.comission_person_tel }}</td></tr>
              <tr><th>メールアドレス</th><td>{{ form.comission_person_email }}</td></tr>
            </tbody>
          </v-simple-table>
          </v-card-text>
        </v-card>
        <v-col lg="4" md="4" sm="4" align-self="center">
          <v-btn v-if="inputable" class="ma-4" color="blue darken-4 outline" outlined @click="$emit('invoice')">
            <v-icon class="mr-1">mdi-pencil-outline </v-icon>
            利用料ご請求先情報を修正する</v-btn>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            width="80%"
        >
          <v-card >
            <v-card-actions class="justify-end">
              <v-btn
                  class="mr-2 custom-transform-class text-none"
                  outlined
                  color="indigo"
                  @click="downloadTermsPDF('use-and-rules')"
              >
                <v-icon class="mr-1">mdi-download</v-icon>
                meviy partners パートナー利用規約・ルール
              </v-btn>
            </v-card-actions>
            <TermsOfUse />
            <v-divider></v-divider>
            <FeePayment />
            <v-card-actions class="justify-center">
              <v-btn
                  color="grey"
                  text
                  @click="dialog=false"
                  class="mb-10"
              >
                閉じる
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <validation-provider
        v-slot="{ errors }"
        rules="required:invalidateFalse"
        name="修正についての確認"
      >
        <v-checkbox
            v-if="inputable"
            :error-messages="errors"
          label="登録申請をするとこのサイトから内容の修正ができないことを確認して送信します"
          type="checkbox"
          v-model="checkbox"
          value="1"
            class="ml-2"
        ></v-checkbox>
      </validation-provider>
      <v-btn
          v-if="inputable"
          class="ml-2 mr-5"
          :loading="save_loading"
          :disabled="save_disabled"
          @click="save()"
      >
        一時保存する
      </v-btn>
      <v-btn
          v-if="inputable"
          class="ml-5"
        type="submit"
          :loading="loading"
        :disabled="invalid || !validated || submit_disabled"
      >
        登録する
      </v-btn>
      <span v-if="inputable" class="ml-3 mt-0 mb-2 text--secondary text-caption">
        登録申請すると、申請者様とご担当者様宛に申請受付メールが自動送信されます。
      </span>
    </form>
    </validation-observer>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import '@mdi/font/css/materialdesignicons.css'
import mepoputil from "@/util/download";
import TermsOfUse from '@/components/TermsOfUse'
import FeePayment from '@/components/FeePayment'
import pdfMake from 'pdfmake/build/pdfmake';
import i18n from '@/i18n'
import store from "@/store";

setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須入力項目です',
})
export default {
  name: 'Registry',
  title: '内容確認',
  components: {
    ValidationProvider,
    ValidationObserver,
    FeePayment: FeePayment,
    TermsOfUse: TermsOfUse,
  },
  props: {
    form: PartnerForm,
    inputable: Boolean,
  },
  data: () => ({
    checkbox: false,
    dialog: false,
    loading: false,
    save_loading: false,
    save_disabled: false,
    submit_disabled: false,
  }),
  computed: {
    invoice_no () {
      if (this.form.invoice_no) {
          return `T${this.form.invoice_no}`;
      }
      return "";
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.save_disabled = true
      this.$refs.observer.validate()
      this.$emit('update:validation_confirm', true)
      this.$emit('update:temporary_save', false)
      this.$emit('register')
    },
    save () {
      console.log("save called")
      this.save_loading = true
      this.submit_disabled = true
      this.$emit('update:temporary_save', true)
      this.$emit('register')
    },
    getFileNames(obj) {
      let names = ""
      if ( obj ) {
        for (const item of obj) {
          names = names + item.name + " "
        }
      }
      return names
    },
    async downloadTermsPDF(type) {
      await mepoputil.downloadTermsPDF(type)
    },
    onDownloadPDFClickWithPDFMake() {
      const uri = new URL(window.location.href);
      //console.log(uri.origin)
      const fonts = pdfMake.fonts = {
        Kosugi: {
          normal: uri.origin + '/assets/Kosugi-Regular.ttf',
          bold: uri.origin + '/assets/Kosugi-Regular.ttf',
          italics: uri.origin + '/assets/Kosugi-Regular.ttf',
          bolditalics: uri.origin + '/assets/Kosugi-Regular.ttf',
        },
      };

      // PDF出力する内容の定義
      const docDefinition = {
        info: {
          title: 'meviy partners パートナー登録申請内容',
          author: 'meviy partners',
          subject: 'meviy partners パートナー登録申請内容の控えとしてご利用ください',
        },
        footer: function(currentPage, pageCount) { return {text: currentPage.toString() + ' / ' + pageCount, alignment: 'center' }},
        content: [
          {
            text: 'meviy partners パートナー登録申請内容',
            style: 'header',
            lineHeight: 1.2
          },
          {
            text: '※パートナー利用規約およびパートナー利用ルールへの同意済み',
            style: { fontSize: 10 },
            lineHeight: 1.2
          },
          {
            text: 'ご登録者様: ' + store.state.user.attributes.name,
            style: { fontSize: 12 },
            alignment: 'right',
            lineHeight: 1.2
          },
          {
            text: '受付番号: ' + (this.form.id ? this.form.id : '(未発行)'),
            style: { fontSize: 12 },
            alignment: 'right',
            lineHeight: 1.2
          },
          {
            text: '出力日時: ' + this.getCurrentTimeStampToPrint(),
            style: { fontSize: 10 },
            alignment: 'right',
            lineHeight: 1.2
          },
          {
            text: ' ',
            style: { fontSize: 12 },
          },
          {
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['30%', '*'],
              body: [
                [ {
                  text: '会社・代表者情報',
                  color: 'grey',
                  style: 'subheader'
                }, ''],
                ['会社名', this.form.company_name_kanji],
                ['会社名（カナ）', this.form.company_name_kana],
                ['本社郵便番号', this.form.zip_cd],
                ['本社住所1', this.form.address_1_kanji],
                ['本社住所2', this.form.address_2_kanji],
                ['本社住所1（カナ）', this.form.address_1_kana],
                ['本社住所2（カナ）', this.form.address_2_kana],
                ['代表TEL', this.form.tel],
                ['インボイス登録番号', this.invoice_no],
                ['代表者氏名', this.form.representative_name],
                ['代表者役職', this.form.representative_title],
              ]
            }
          },
          {
            text: ' ',
            style: { fontSize: 12 },
          },
          {
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['30%', '*'],
              body: [
                [ {
                  text: '担当者情報',
                  color: 'grey',
                  style: 'subheader'
                }, ''],
                ['担当者名 姓', this.form.person_name_sei_kanji],
                ['担当者名 名', this.form.person_name_mei_kanji],
                ['担当者名 姓（カナ）', this.form.person_name_sei_kana],
                ['担当者名 名（カナ）', this.form.person_name_mei_kana],
                ['部課名', this.form.buka_name_kanji],
                ['部課名（カナ）', this.form.buka_name_kana],
                ['担当者TEL', this.form.person_tel],
                ['担当者FAX', this.form.person_fax],
                ['メールアドレス', this.form.person_email],
              ]
            }
          },
          {
            text: ' ',
            style: { fontSize: 12 },
          },
          {
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['30%', '*'],
              body: [
                [ {
                  text: '銀行口座情報',
                  color: 'grey',
                  style: 'subheader'
                }, ''],
                ['銀行コード', this.form.bank_code],
                ['支店コード', this.form.branch_code],
                ['銀行名', this.form.bank_name],
                ['支店名', this.form.branch_name],
                ['預金種類', i18n.tc(this.form.account_type)],
                ['口座番号', this.form.account_number],
                ['口座名義', this.form.account_name],
                ['登録済 添付ファイル', this.getFileNames(this.form.bank_attachment_filename)],
                ['新規 添付ファイル', this.getFileNames(this.form.bank_attachment)],
              ]
            }
          },
          {
            text: ' ',
            style: { fontSize: 12 },
          },
          {
            layout: 'lightHorizontalLines',
            table: {
              headerRows: 1,
              widths: ['30%', '*'],
              body: [
                [ {
                  text: '利用料ご請求先情報',
                  color: 'grey',
                  style: 'subheader'
                }, ''],
                ['担当者名 姓', this.form.comission_person_name_sei_kanji],
                ['担当者名 名', this.form.comission_person_name_mei_kanji],
                ['担当者名 姓（カナ）', this.form.comission_person_name_sei_kana],
                ['担当者名 名（カナ）', this.form.comission_person_name_mei_kana],
                ['担当者TEL', this.form.comission_person_tel],
                ['メールアドレス', this.form.comission_person_email],
              ]
            }
          },
        ],
        defaultStyle: {
          font: 'Kosugi',
          fontSize: 10,
        },
        styles: {
          header: {
            fontSize: 20,
          },
          subheader: {
            fontSize: 15,
          },
        },
      };

      // pdfMakeでのPDF出力
      pdfMake.createPdf(docDefinition, null, fonts).download('meviy partners パートナー登録申請内容');
    },
    getCurrentTimeStampToPrint () {
      const now = new Date()
      return (now.getFullYear() + "/"
          + ("0"+(now.getMonth() + 1)).slice(-2) + "/"
          + ("0"+now.getDate()).slice(-2) + " "
          + ("0"+now.getHours()).slice(-2) + ":"
          + ("0"+now.getMinutes()).slice(-2))
    }
  },
  async mounted(){
    await this.$nextTick(async function () {
      if (this.form.isviewform != null) {
        if (!this.form.isviewform && this.form.id) { // 登録フローかつ登録完了している
          this.$router.push('/viewlist')
        } else if (!this.form.isviewform && (!this.form.validation_supplier || !this.form.validation_person || !this.form.validation_bank || !this.form.validation_invoiceperson)) { // 登録フローかつ前のステップが欠損している
          this.$router.push({hash: '#supplier'})
        }
      }
    })
  },
  async created () {
    this.$emit('update:company_name_kanji', this.form.company_name_kanji)
    this.$emit('update:company_name_kana', this.form.company_name_kana)
    this.$emit('update:zip_cd', this.form.zip_cd)
    this.$emit('update:address_1_kanji', this.form.address_1_kanji)
    this.$emit('update:address_2_kanji', this.form.address_2_kanji)
    this.$emit('update:address_1_kana', this.form.address_1_kana)
    this.$emit('update:address_2_kana', this.form.address_2_kana)
    this.$emit('update:tel', this.form.tel)
    this.$emit('update:fax', this.form.fax)
    this.$emit('update:invoice_no', this.form.invoice_no)
    this.$emit('update:representative_name', this.form.representative_name)
    this.$emit('update:representative_title', this.form.representative_title)
    this.$emit('update:person_name_sei_kanji', this.form.person_name_sei_kanji)
    this.$emit('update:person_name_mei_kanji', this.form.person_name_mei_kanji)
    this.$emit('update:person_name_sei_kana', this.form.person_name_sei_kana)
    this.$emit('update:person_name_mei_kana', this.form.person_name_mei_kana)
    this.$emit('update:person_tel', this.form.person_tel)
    this.$emit('update:person_fax', this.form.person_fax)
    this.$emit('update:buka_name_kanji', this.form.buka_name_kanji)
    this.$emit('update:buka_name_kana', this.form.buka_name_kana)
    this.$emit('update:person_email', this.form.person_email)
    this.$emit('update:person_email_confirm', this.form.person_email_confirm)
    this.$emit('update:bank_code', this.form.bank_code)
    this.$emit('update:branch_code', this.form.branch_code)
    this.$emit('update:bank_name', this.form.bank_name)
    this.$emit('update:branch_name', this.form.branch_name)
    this.$emit('update:account_type', this.form.account_type)
    this.$emit('update:account_number', this.form.account_number)
    this.$emit('update:account_name', this.form.account_name)
    this.$emit('update:bank_attachment', this.form.bank_attachment)
    this.$emit('update:bank_attachment_filename', this.form.bank_attachment_filename)
    this.$emit('update:comission_person_name_sei_kanji', this.form.comission_person_name_sei_kanji)
    this.$emit('update:comission_person_name_mei_kanji', this.form.comission_person_name_mei_kanji)
    this.$emit('update:comission_person_name_sei_kana', this.form.comission_person_name_sei_kana)
    this.$emit('update:comission_person_name_mei_kana', this.form.comission_person_name_mei_kana)
    this.$emit('update:comission_person_tel', this.form.comission_person_tel)
    this.$emit('update:comission_person_email', this.form.comission_person_email)
    this.$emit('update:comission_person_email_confirm', this.form.comission_person_email_confirm)
  }

}
</script>
