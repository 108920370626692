<template>
<v-card class="ma-5" flat>

  <v-card-title class="text-h5 ma-5">
    委託料等について（委託料ルール）
  </v-card-title>

  <div class="text-body-2 ma-10">
    ミスミは、以下の定めに従い、顧客、カード会社又はDGFTから受領した委託料等をパートナーに支払います。
  </div>
  <div class="text-body-2 ma-10">
    １	締日<br/>
    毎月1日から末日（委託料等締日）までにパートナーが出荷した本製品等の委託料等を翌月にパートナーにお支払いします。
  </div>
  <div class="text-body-2 ma-10">
    ２	支払期日<br/>
    委託料等の支払期日は、委託料等締日の翌月の末日です。
  </div>
  <div class="text-body-2 ma-10">
    ３	支払方法<br/>
    支払期日までにパートナーが別途指定する金融機関への口座振り込みにて支払います。
  </div>
  <div class="text-body-2 ma-10">
    ４	振込手数料<br/>
    パートナーへお支払いする委託料等の振込手数料は、ミスミ負担とします。ただし、パートナーからミスミへの委託料等の返金等にかかる振込手数料は、パートナー負担とします。
  </div>
  <div class="text-body-2 ma-10" align="right">
    以上<br/>
    制定日：2021年8月12日<br/>
    改定日：2024年6月24日
  </div>

  <v-divider></v-divider>

  <v-card-title class="text-h5 ma-5">
    利用料及びその他費用について（利用料ルール）
  </v-card-title>

  <div class="text-h6 ma-5">
    １	利用料等の種類
  </div>
  <div class="text-body-2 ma-10 mb-2">
    (1)	本プラットフォーム利用料<br/>
    本プラットフォーム利用料については、別段の定めがない限りは、以下のとおりとします。<br/>
    ・	月額 60,000円（税抜）<br/>
    ・	ただし、本ウェブサイトの利用開始月翌月から12カ月間は無償<br/>
    ※	例：利用開始日が2021年9月10日の場合、2021年9月～2022年9月は無償<br/>
    ※	利用開始日：本ウェブサイトへのログインID発行日<br/>
  </div>
  <div class="text-body-2 ma-10 mb-2">
    (2)	販売手数料<br/>
    販売手数料の算定方法は以下のとおりです。<br/>
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
    <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            適用対象
          </th>
          <th class="text-left">
            販売手数料算定対象金額の水準
          </th>
          <th class="text-left">
            販売手数料（税抜）
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td rowspan="2">１個別契約成立毎に</td>
          <td>1円～5,000円未満</td>
          <td>500円</td>
        </tr>
        <tr>
          <td>5,000円以上</td>
          <td>
            販売手数料算定対象金額の<br />
            ・50万円以下部分の10%、<br />
            ・50万円超部分については5%<br />
            例）51万円の場合、<br />
            50万円X10%+1万円X5%=50,500円
          </td>
        </tr>
        </tbody>
    </v-simple-table>
    </v-card>
  </div>
  <div class="text-caption ml-15 mt-0">
    個別契約の変更・キャンセルが発生していない場合、当該個別契約の販売手数料算定対象金額は委託料等と同額です。委託料等の内訳は、meviy partnersパートナー利用規約第１７条に記載のとおりです。<br />
    個別契約の変更・キャンセルが発生した場合の販売手数料の算定方法は、変更・キャンセルポリシーに記載のとおりです。
  </div>
  <div class="text-body-2 ma-10">
    (3)	オプションサービス利用料<br />
    オプションサービス利用料については、都度定めます。<br />
  </div>
  <div class="text-body-2 ma-10">
    (4)	その他ミスミとパートナー間で合意した費用<br />
    その他ミスミとパートナー間で合意した費用については、都度定めます。<br />
  </div>

  <div class="text-h6 ma-5">
    ２	支払方法
  </div>
  <div class="text-body-2 ma-10">
    パートナーは、以下の定めに従い、利用料等をミスミに支払います。<br/>
    (1)	本プラットフォーム利用料の支払方法<br/>
    ①	計算単位<br/>
    利用開始日に関わらず、利用月単位（月額）でのお支払いとなります。<br/>
    ※	利用月とは、パートナーが本ウェブサイトへログイン可能な状態にある月です（本ウェブサイトへのログインの有無は問いません）。<br/>
    ※	例：ログイン可能な状態にある日が、2021年9月10日～24日、2021年9月1日～30日いずれの場合も、9月分のプラットフォーム利用料は同額<br/>
    ※	退会時等における取り扱いは別途定めます。<br/>
    ②	支払期日<br/>
    毎月1日から月末までの利用料を、翌月末日までにお支払い下さい（毎月末締め、翌月末払い）。<br/>
    ③	支払方法<br/>
    支払期日までにミスミが指定する金融機関への口座振り込みにてお支払い下さい。<br/>
  </div>
  <div class="text-body-2 ma-10">
    (2)	販売手数料の支払方法<br/>
    ①	締日<br/>
    販売手数料は毎月20日を締日とします。<br/>
    販売手数料は、個別契約成立時に、各個別契約の委託料等に応じてそれぞれ算定します。<br/>
    ミスミは、個別契約ごとに算定された販売手数料のうち、前月21日から当月20日まで（販売手数料算定期間）に出荷された本製品等の販売手数料を合算し、パートナーに請求します。<br/>
    <br/>
    ただし、個別契約の変更・キャンセル等があった場合の販売手数料算定期間は、以下のとおりとします。<br/>
    (i)	販売手数料算定期間（算定方法）<br/>
    a. 変更連絡時が本製品等出荷前の場合<br/>
    販売手数料算定期間は、本製品等出荷時の販売手数料算定期間です。<br/>
    b. キャンセル連絡時が本製品等出荷前の場合<br/>
    販売手数料算定期間は、キャンセル発生時の販売手数料算定期間です。<br/>
    c. 変更・キャンセル連絡時が本製品等出荷後の場合<br/>

    <v-card elevation="1" class="ml-10 mr-10 mt-3 mb-5">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            本製品等出荷日と変更・キャンセル発生日の販売手数料算定期間
          </th>
          <th class="text-left">
            基準となる算定期間（算定方法）
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>同一</td>
          <td>本製品等出荷時の販売手数料算定期間</td>
        </tr>
        <tr>
          <td>異なる</td>
          <td>変更・キャンセル発生時の販売手数料算定期間（差額のみを計算）</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    ②	支払期日<br/>
    販売手数料の支払期日は、締日（①に記載）の翌月の末日です。<br/>
    ③	支払方法<br/>
    支払期日までにミスミが指定する金融機関への口座振り込みにてお支払いください。<br/>
  </div>
  <div class="text-body-2 ma-10">
    (3)	本ウェブサイト利用料及び販売手数料以外の利用料等の支払方法<br/>
    別途定めます。<br/>
  </div>

  <div class="text-h6 ma-5">
    ３	利用料等及びその他費用の振込手数料
  </div>
  <div class="text-body-2 ma-10">
    パートナーからミスミへの利用料等の入金における振込手数料は、パートナー負担とします。ただし、ミスミからパートナーへの利用料等の返金等にかかる振込手数料は、ミスミ負担とします。
  </div>
  <div class="text-body-2 ma-10" align="right">
    以上<br/>
    制定日：2021年8月12日<br/>
    改定日：2024年6月24日<br/>
  </div>

  <v-divider></v-divider>

  <v-card-title class="text-h5 ma-5">
    個別契約ルール
  </v-card-title>
  <div class="text-body-2 ma-10">
    本ルールでは、本規約第１５条で定める個別契約について、詳細なルールを定めます。なお、本ルールで使用する用語の意味は、本ルールに特段の定めがない限り、利用規約で使用する用語の意味に従うものとします。
  </div>

  <div class="text-h6 ma-5">
    １	定義
  </div>
  <div class="text-body-2 ma-10">
    本ルール又は本プラットフォーム上で使用する用語の意義及び基本条件は、以下のとおりとします。<br/>
  </div>
  <div class="text-body-2 ma-10">
    (1)	製品要件<br/>
    個別契約の対象となる本製品等の仕様に関する要件をいいます。
  </div>
  <div class="text-body-2 ma-10">
    (2)	取引条件 <br/>
    個別契約に関する委託料等や実働日などの詳細条件（ただし製品要件を除く）をいいます。
  </div>
  <div class="text-body-2 ma-10">
    (3)	製品要件設定<br/>
    パートナーが顧客から本製品等の発注を受けた際に適用する製品要件を予め本プラットフォーム上で設定すること及びこれを変更することを、製品要件設定といいます。
  </div>
  <div class="text-body-2 ma-10">
    (4)	取引条件設定 <br/>
    パートナーが顧客から本製品等の発注を受けた際に適用する取引条件を予め設定すること及びこれを変更することを、取引条件設定といいます。<br/>
    なお、取引条件設定には、受注停止及び当該受注停止の解除も含まれます。
  </div>
  <div class="text-body-2 ma-10">
    (5)	製品要件書<br/>
    製品要件を記載した書類（本ウェブサイト上で発行されるものを含みます。以下「書類」について同じ。）を製品要件書といいます。
  </div>
  <div class="text-body-2 ma-10">
    (6)	見積依頼<br/> 
    顧客が特定のパートナーに対し、製品要件及び取引条件の全体又は一部を指定する形で見積回答を依頼することを見積依頼といいます。
  </div>
  <div class="text-body-2 ma-10">
    (7)	見積回答<br/>
    顧客から見積依頼を受けたパートナーが当該顧客に対して、顧客が当該パートナーへ発注する場合に適用される製品要件及び取引条件の全体を回答することを見積回答といいます。
  </div>
  <div class="text-body-2 ma-10">
    (8)	見積書<br/> 
    顧客が、特定の製品要件及び数量等の取引条件を指定して発注を行う場合の発注金額等を表す書類をいいます。
  </div>
  <div class="text-body-2 ma-10">
    (9)	meviy partners型番<br/>
    顧客が本プラットフォーム又はミスミの販売サイト、ミスミが提供する他の注文媒体を介して、meviy partnersのパートナーへ発注するためのコードをmeviy partners型番（以下、「mep型番」といいます）といいます。顧客がmep型番を指定し発注すると、当該型番に紐づいた特定のパートナーが発注先として設定され、有効な製品要件及び取引条件が発注に適用されます。
  </div>
  <div class="text-body-2 ma-10">
    (10)	簡易登録ユーザー<br/>
    顧客のうち、支払い方法を登録しておらずお客様コードNO．が発行されていない顧客をいいます。
  </div>
  <div class="text-h6 ma-5">
    ２	個別契約の内容
  </div>
  <div class="text-body-2 ma-10">
    個別契約の内容は、本規約及びルール等に定める条件の他は以下に記載される条件に限るものとし、その他顧客パートナー間で別途合意した条件があったとしても、本プラットフォーム上の個別契約の内容にはならないものとします。<br />
    ①	製品要件<br />
    ②	取引条件<br />
    ③	発注時又は発注後に顧客又はパートナーより指定される以下の条件<br />
    (i)	本製品等の数量<br />
    (ii)	発注日<br />
    (iii)	出荷日<br />
    (iv)	納品先<br />
    ④	その他、ミスミが別途指定する方法にて顧客及びパートナー間で合意された事項<br />
  </div>
  <div class="text-h6 ma-5">
    ３	個別契約に適用可能な条件等の設定
  </div>
  <div class="text-body-2 ma-10">
    (1)	個別契約に適用可能な条件の成立タイミング<br />
    個別契約の内容のうち製品要件及び取引条件は、発注より前に本プラットフォーム上で予め登録されている必要があります。<br />
    パートナーによる製品要件設定及び取引条件設定は、以下いずれかのタイミングにおいて本プラットフォーム上で登録され、顧客に本プラットフォーム上で開示された時点に定まるものとします。<br />
    ①	顧客からパートナーへの見積依頼に対する見積回答を行うタイミング<br />
    ②	その他のミスミの定める方法で登録可能なタイミング
  </div>
  <div class="text-body-2 ma-10">
    (2)	有効期限<br />
    パートナーが取引条件設定及び見積回答に対して設定する有効期限を、それぞれ取引条件有効期限、見積有効期限といいます。<br />
    <br />
    Cf.
    <v-card elevation="1" class="ml-10 mr-10 mt-3 mb-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            条件
          </th>
          <th class="text-left">
            有効期限
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>取引条件</td>
          <td>取引条件有効期限</td>
        </tr>
        <tr>
          <td rowspan="2">見積回答</td>
          <td>見積有効期限</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    見積有効期限は、当該見積回答が対応する取引条件の取引条件有効期限を超えないものとします。<br />
    パートナーは、各有効期限内においては取引条件設定又は見積回答内容を、特段の定めのない限り変更又は無効化（受注停止を含む。以下同じ。）できないものとします。<br />
    パートナーは、取引条件有効期限を設定していない場合、又は取引条件有効期限若しくは見積有効期限を超過している場合、取引条件設定を任意のタイミングで変更又は無効化することができます。
  </div>
  <div class="text-h6 ma-5">
    ４	見積回答
  </div>
  <div class="text-body-2 ma-10">
    (1)	パートナーは、顧客から見積依頼を受領した場合は顧客に対し、パートナーの４営業時間以内に、以下いずれかの対応を行わなければなりません。<br />
    ①	見積回答<br />
    ②	見積回答辞退連絡<br />
    ③	①又は②のための連絡（見積回答予定日連絡、顧客への質問等）
  </div>
  <div class="text-body-2 ma-10">
    (2)	パートナーが見積回答をする場合は、顧客が当該パートナーへ発注する場合に適用される製品要件及び取引条件の全てを明記する必要があります。見積回答は、見積依頼の受領前又は受領後に、パートナーが製品要件設定及び取引条件設定を行い、これを顧客に開示することで行われます。
  </div>
  <div class="text-body-2 ma-10">
    (3)	パートナーは、顧客からの見積依頼に対し見積回答を辞退することができます。 
  </div>
  <div class="text-body-2 ma-10">
    (4)	パートナーは、簡易登録ユーザーから見積依頼を受領し見積回答を行う場合、簡易登録ユーザーが支払い方法を登録していないことにより想定される事項（具体的な発注がなされない等）を踏まえて回答を行うことに合意します。
  </div>
  <div class="text-h6 ma-5">
    ５	条件等の確認方法
  </div>
  <div class="text-body-2 ma-10">
    (1)	個別契約に適用可能な条件等は、以下の媒体でその全部又は一部を確認できるものとします。<br />
    ①	本ウェブサイト上で発行される製品要件書<br />
    ②	本ウェブサイト上で発行される見積書<br />
    ③	本ウェブサイト上の画面<br />
    ④	その他ミスミの販売サイト、ミスミが提供する他の注文媒体の画面、発行される書類等
  </div>
  <div class="text-body-2 ma-10">
    (2)	新たに発行される見積書には、その時点で有効な取引条件設定又は見積回答内容が反映されます。 
  </div>
  <div class="text-body-2 ma-10">
    (3)	見積書には、（ⅰ）本ウェブサイト上で発行されるもの（上記②）、（ⅱ）ミスミ販売サイト又はミスミが提供する他の注文媒体で発行されるもの（上記④）があります。（i）と（ⅱ）の内容に乖離がある場合、発注には（i）が適用されます。
  </div>
  <div class="text-h6 ma-5">
    ６	mep型番による個別契約への条件等の適用
  </div>
  <div class="text-body-2 ma-10">
    (1)	パートナーから顧客への見積回答が完了すると同時に、当該顧客が見積回答に対応する製品要件及び取引条件を発注に適用するために使用するmep型番が、本ウェブサイト上で指定されます。本ウェブサイト上で指定されるmep型番は、以下のとおり、顧客からパートナーへの見積依頼方法によって異なります。<br />
    <br />
    Cf.    
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            見積依頼方法
          </th>
          <th class="text-left">
            指定されるmep型番
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>顧客が過去に発行された型番を指定し、同一型番での見積回答をパートナーに依頼した場合</td>
          <td>顧客が見積依頼時に指定した型番</td>
        </tr>
        <tr>
          <td>上記以外</td>
          <td>パートナーによる見積回答が完了すると同時に、本ウェブサイト上で新たに発行される型番</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
  <div class="text-body-2 ma-10">
    (2)	顧客からパートナーに対する発注は、mep型番を使用して行う必要があります。顧客が発注時にmep型番を指定することで、当該mep型番に対応した有効な製品要件設定及び取引条件設定が発注に反映されます。
  </div>
  <div class="text-body-2 ma-10">
    (3)	mep型番に対応する製品要件及び取引条件は、本ルール「条件等の確認方法」に記載のとおりです。
  </div>
  <div class="text-h6 ma-5">
    ７	型番タイプ
  </div>
  <div class="text-body-2 ma-10">
    (1)	型番には以下のタイプがあり、タイプに応じ当該型番を使用して発注可能な顧客が異なります。各型番タイプに対応する顧客は、以下の表のとおりです。
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            型番タイプ
          </th>
          <th class="text-left">
            当該型番を使用して発注可能な顧客
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>マイ型番（my型番）</td>
          <td>見積依頼を行い、パートナーからの見積回答時に当該型番が生成されたお客様コードＮｏ．のみ</td>
        </tr>
        <tr>
          <td>共通型番</td>
          <td>
            当該共通型番を知るお客様コードＮｏ．<br />
            ※詳細は(3)記載のとおり
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
  <div class="text-body-2 ma-10">
    (2)	簡易登録ユーザーは、共通型番を前提とした見積依頼のみ可能です。パートナーは、簡易登録ユーザーからの見積依頼に対するパートナーからの見積回答に基づき生成されたmep型番は、共通型番としてのみ扱われることを理解しこれに同意します。なお、各整理は以下の表のとおりです。
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left" rowspan="2">型番タイプ</th>
          <th class="text-center" colspan="2">見積依頼可能な顧客</th>
        </tr>
        <tr>
          <th class="text-left">通常顧客<br />（簡易登録ユーザー以外）</th>
          <th class="text-left">簡易登録ユーザー</th>  
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>マイ型番（my型番）</td>
          <td>可</td>
          <td>不可</td>
        </tr>
        <tr>
          <td>共通型番</td>
          <td>可</td>
          <td>可</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
  <div class="text-body-2 ma-10">
  (3)	共通型番の扱いは以下のとおりです。なお、下記に定める事項によって顧客又はパートナーに損害が生じてもミスミは一切の責任を負いません。<br />
  ①	顧客がパートナーに対し、共通型番として扱われることを前提とした見積依頼を行い、パートナーから当該顧客に対する見積回答が完了すると、共通型番として扱われるmep型番が生成されます。なお、共通型番を生成した顧客を以下「共通型番生成顧客」といいます。<br />
  ②	共通型番生成顧客は、自己の責任において、他の顧客に対し当該共通型番に関する情報を開示することができます。<br />
  ③	他の顧客がパートナーに対して、共通型番を指定する方法で当該共通型番に関する製品要件設定及び取引条件設定について照会した場合、パートナーは、共通型番生成顧客に前号に基づく他の顧客への開示の有無を確認することなく当該製品要件設定及び取引条件設定の内容を開示できます。ただし、パートナーは、当該共通型番を知らない他の顧客に対し、共通型番生成顧客の承諾を得ることなく、自ら当該共通型番に関する情報を開示することはできません。<br />
  ④	他の顧客は、自己のお客様コードＮｏ．を使い、本ウェブサイト、ミスミの販売サイト若しくはミスミが顧客に提供する各注文チャネル上で、当該型番を入力して発注できます。なお、当該発注に基づくお支払いは、当該他の顧客の負担となり、共通型番生成顧客へ請求されることはありません。<br />
  ⑤	パートナーは当該型番に紐づく取引条件設定又は見積回答（ただし、有効期限内のものに限る。）と矛盾しない範囲において、新たな取引条件設定及び見積回答を行うことができます。<br />
  ⑥	共通型番生成顧客は、他の顧客が当該型番に関する情報を得ること、及び当該型番を用いた発注により当該型番に対応する本製品等を取得することに対し、一切の権利を主張しません。
  </div>
  <div class="text-h6 ma-5">
    ８	個別契約の成立
  </div>
  <div class="text-body-2 ma-10">
    (1)	個別契約の成立<br />
    個別契約は、顧客が本ウェブサイト上又はミスミの販売サイト若しくはミスミが顧客に提供する各注文チャネルを介してパートナーに対し発注を行い、パートナーがこれを承諾した時点で成立します。<br />
    個別契約が成立すると、本ウェブサイト上で「注文確定」と表示されます。
  </div>
  <div class="text-body-2 ma-10">
    (2)	パートナーによる発注の自動承諾<br />
    顧客が発注をした場合、当該発注が以下の①乃至③の全ての条件を満たす限り、パートナーは当該発注を承諾し、また成立後の個別契約については顧客の同意なしに取消しできないものとします。<br />
    ①	パートナーが本プラットフォーム上で登録し当該顧客へ開示した取引条件設定又は見積回答の範囲内であること<br />
    ②	取引条件有効期限又は見積有効期限が設定されている場合、発注のタイミングが有効期限内であること<br />
    ③	顧客・パートナー間で別段の取り決めがされていないこと
  </div>
  <div class="text-body-2 ma-10">
    (3)	取引条件適用の免責<br />
    システムの都合上、発注に適用される取引条件が、発注時点で設定されている有効な取引条件設定や見積回答の内容と異なる場合があります。この場合、本プラットフォーム上で発注確定通知が行われたとしても、個別契約は不成立として扱います。なおこの場合、ミスミは当該発注を速やかに取り消すものとし、当該取り消しにより顧客又はパートナーに生じるいかなる損害に対してもミスミは責任を負いません。
  </div>
  <div class="text-body-2 ma-10">
    (4)	発注に対する審査について<br />
    ミスミによる発注に対する審査等（本規約第25条に定める利用限度額に関する審査を含みますが、これに限りません）の結果次第では、顧客の発注をミスミが拒否する場合、又は一度確定した個別契約を速やかに取り消す場合があります。なお、当該拒否又は取り消しによる顧客及びパートナーに生じた損害について、ミスミは一切責任を負わないものとします。
  </div>
  <div class="text-h6 ma-5">
    ９	本プラットフォーム外での取引
  </div>
  <div class="text-body-2 ma-10">
    (1)	パートナーは、本プラットフォーム上で顧客から見積依頼を受けた個別契約は、本ウェブサイト上又はミスミの販売サイト若しくはミスミが顧客に提供する各注文チャネルで受注する必要があります。
  </div>
  <div class="text-body-2 ma-10">
    (2)	前項の定めに関わらず、パートナーが本ウェブサイトを通じて見積依頼を受けた個別契約を本ウェブサイト又はミスミの販売サイト若しくはミスミが顧客に提供する各注文チャネル以外で受注した場合、ミスミは、当該個別契約が本プラットフォーム上で成立したものとみなし、当該個別契約にかかる本規約第１１条に定める利用料等をパートナーに請求できるものとします。ただし、パートナーがこれらの利用料等を支払った場合であっても、パートナーの本規約違反の責任は免責されないものとし、また、顧客とパートナー間で生じたトラブル等の対応について、ミスミは一切の責任を負いません。
  </div>
  <div class="text-h6 ma-5">
    １０	パートナー及び本製品等に関する情報の登録
  </div>
  <div class="text-body-2 ma-10">
    (1)	パートナーは、以下の情報（以下、「パートナーの顧客向け登録情報」といいます）を本プラットフォーム上で登録することにより、当該情報を一般に公開し、また顧客が本プラットフォーム上でのパートナーの検索及びパートナーへの見積依頼時における製品要件や取引条件の指定等を行う際に、当該情報を活用可能にすることができます。ただし、別段の定めがある場合は、ミスミがパートナーに代わり当該情報を本プラットフォーム上で登録することがあります。<br />
    ①	パートナー自身に関する情報<br />
    ②	パートナーが本プラットフォーム上で提供可能な本製品等に関する情報<br />
    ③	その他、顧客に公開することを前提にミスミが定める方法でパートナーが登録する情報
  </div>
  <div class="text-body-2 ma-10">
    (2)	パートナーは、パートナーの顧客向け登録情報が本プラットフォーム上で公開されたときから、本製品等に関する顧客からの見積依頼及び受注を開始することができます。
  </div>
  <div class="text-body-2 ma-10">
    (3)	前項までの定めに関わらず、パートナーの顧客向け登録情報が法令その他の規制に違反する場合、登録情報が事実と異なる場合、ミスミ所定の情報の記載がない場合、本ウェブサイトの利用者に不利益が生じる場合、又はそれらのおそれがある場合、ミスミは、パートナーの顧客向け登録情報を非公開とすることができます。その場合、ミスミは、パートナーの顧客向け登録情報を非公開とすることにより顧客及びパートナーに生じた損害を賠償する責任は負いません。
  </div>
  <div class="text-body-2 ma-10">
    (4)	パートナーは、パートナーの顧客向け登録情報が、ミスミの登録顧客以外を含む一般に公開されることに同意し、ミスミは当該公開によりパートナーに生じた損害を賠償する責任は負いません。
  </div>
  <div class="text-h6 ma-5">
    １１	成立した個別契約の変更・取消
  </div>
  <div class="text-body-2 ma-10">
    上記１条乃至１０条で定める条件以外については、別紙「変更・キャンセルポリシー」に従うものとします。
  </div>
  <div class="text-h6 ma-5">
    １２	その他
  </div>
  <div class="text-body-2 ma-10">
    ミスミは、本規約及びルール等でミスミが責任を負う旨を明示した場合を除き、個別契約に関する紛争解決及び損害を賠償する責任は負いません。
  </div>
  <div class="text-body-2 ma-10" align="right">
    以上<br/>
    制定日：2023年9月27日<br/>
    改定日：2024年6月24日<br/>
  </div>

  <v-divider></v-divider>

  <v-card-title class="text-h5 ma-5">
    変更・キャンセルポリシー（変更・キャンセルルール）
  </v-card-title>
  <div class="text-body-2 ma-10">
    本ポリシーでは、個別契約の内容の一部を変更する場合（変更）及び個別契約を全て解約する場合（キャンセル）について、詳細なルールを定めます。パートナーは、個別契約の変更・キャンセルをする場合に本ウェブサイト上で個別契約の変更・キャンセルの手続を完了させることが必要です。
  </div>
  <div class="text-h6 ma-5">
    １	変更時（個別契約の内容の一部を変更する場合）
  </div>
  <div class="text-body-2 ma-10">
    (1)	顧客都合の変更<br/>
    ①	成立条件<br/>
    個別契約の成立後、以下の全ての条件を満たした場合に当該個別契約の顧客都合の変更が成立します。<br/>
    (i)	顧客がチャット上でパートナーに対し個別契約の変更を申し込むこと<br/>
    (ii)	パートナーがチャット上で(i)の顧客からの変更申し込みへの承諾の可否及び承諾する場合の要件を提示すること<br/>
    (iii)	顧客がチャット上で(ii)でパートナーが示した要件の全てに合意すること<br/>
    (iv)	パートナーが(iii)の合意内容を、パートナーの責任のもと本ウェブサイト上の所定の方法でミスミに連絡すること<br/>
    (v)	ミスミが本ウェブサイト上で(iv)の連絡内容に基づいた設定変更を行うこと<br/>
    ②	販売手数料の算定<br/>
    販売手数料算定対象金額は、変更後の個別契約の委託料等となります。販売手数料は「利用料及びその他費用について（利用料ルール）」に定める販売手数料の算定式に基づいて計算されます。<br/>
  </div>
  <div class="text-body-2 ma-10">
    (2)	パートナー都合の変更<br/>
    ①	成立条件<br/>
    個別契約の成立後、以下の全ての条件を満たした場合に当該個別契約のパートナー都合の変更が成立します。<br/>
    (i)	パートナーがチャット上で顧客に対し個別契約の変更を申し込むこと<br/>
    (ii)	顧客がチャット上で(i)のパートナーからの変更申し込みへの承諾の可否及び承諾する場合の要件を提示すること<br/>
    (iii)	パートナーがチャット上で(ii)で顧客が示した要件の全てに合意すること<br/>
    (iv)	パートナーが(iii)の合意内容を、パートナーの責任のもと本ウェブサイト上の所定の方法でミスミに連絡すること<br/>
    (v)	ミスミが本ウェブサイト上で(iv)の連絡内容に基づいた設定変更を行うこと<br/>
    ②	販売手数料の算定<br/>
    変更後の販売手数料算定対象金額の水準は、変更前の販売手数料算定対象金額と変更後の委託料等の比較に基づき定められます。

    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            変更前の販売手数料算定対象金額と<br />
            変更後の委託料等の比較
          </th>
          <th class="text-left">
            変更後の販売手数料算定対象金額
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>同額</td>
          <td>変更なし<br />（変更前の販売手数料算定対象金額と同額）</td>
        </tr>
        <tr>
          <td>変更後の委託料等の方が高い</td>
          <td>変更後の委託料等と同額</td>
        </tr>
        <tr>
          <td>変更前の販売手数料算定対象金額の方が高い</td>
          <td>変更前の販売手数料算定対象金額と同額</td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <div class="text-caption ml-15">
      販売手数料は、変更後の販売手数料算定対象金額に対し、「利用料及びその他費用について（利用料ルール）」に定める販売手数料の算定式に基づいて計算されます。
    </div>
  </div>

  <div class="text-h6 ma-5">
    ２	キャンセル時（個別契約を全て解約する場合）
  </div>

  <div class="text-body-2 ma-10">
    (1)	顧客都合のキャンセル<br/>
    ①	成立条件<br/>
    個別契約の成立後、以下の全ての条件を満たした場合に当該個別契約の顧客都合のキャンセルが成立します。<br/>
    (i)	顧客がチャット上でパートナーに対し個別契約のキャンセルを申し込むこと<br/>
    (ii)	パートナーがチャット上で(i)の顧客からのキャンセル申し込みへの承諾の可否及び顧客に請求するキャンセル料（詳細後述）の全額を提示すること<br/>
    (iii)	顧客がチャット上(ii)でパートナーが示したキャンセル料の全額負担に合意すること<br/>
    (iv)	パートナーが(iii)の合意内容を、パートナーの責任のもと本ウェブサイト上の所定の方法でミスミに連絡すること<br/>
    (v)	ミスミが本ウェブサイト上で(iv)の連絡内容に基づいた設定変更を行うこと<br/>
    ②	販売手数料の算定<br/>
    キャンセル後の販売手数料算定対象金額は、キャンセル料と同額です。<br/>
    販売手数料の計算方法は以下のとおりです。
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">適用対象</th>
          <th class="text-left">販売手数料（税抜）</th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>１個別契約毎に</td>
            <td>
              販売手数料算定対象金額の<br/>
              ・50万円以下部分の10%、<br/>
              ・50万円超部分については5%<br/>
              例）51万円の場合、<br/>
              50万円X10%+1万円X5%=50,500円
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>

    <div class="text-caption ml-15">キャンセル料を無償とする場合は、本手数料も無償とします。</div>
  </div>
  <div class="text-body-2 ma-10">
    (2)	パートナー都合のキャンセル<br/>
    ①	成立条件<br/>
    個別契約の成立後、以下の全ての条件を満たした場合に当該個別契約のパートナー都合のキャンセルが成立します。<br/>
    (i)	パートナーがチャット上で顧客に対し個別契約のキャンセルを申し込むこと<br/>
    (ii)	顧客がチャット上で(i)のパートナーからのキャンセル申し込みへの承諾の可否及び承諾する場合の要件を提示すること<br/>
    (iii)	パートナーがチャット上で(ii)で顧客が示した要件の全てに同意すること<br/>
    (iv)	パートナーが(iii)の合意内容を、パートナーの責任のもと本ウェブサイト上の所定の方法でミスミに連絡すること<br/>
    (v)	ミスミが本ウェブサイト上で(iv)の連絡内容に基づいた設定変更を行うこと<br/>
    <br/>
    ②	販売手数料の算定<br/>
    キャンセル後の販売手数料算定対象金額は、キャンセル前の販売手数料算定対象金額とキャンセル料の比較に基づき定められます。<br/>
    <v-card elevation="1" class="ml-10 mr-10 mt-3">
      <v-simple-table>
        <thead class="blue-grey lighten-5">
        <tr>
          <th class="text-left">
            キャンセル前の販売手数料算定対象金額と<br />
            キャンセル料の比較
          </th>
          <th class="text-left">
            キャンセル後の販売手数料算定対象金額
          </th>
        </tr>
        </thead>
        <tbody>
          <tr>
            <td>同額</td>
            <td>
              変更なし<br/>
              （キャンセル前の販売手数料算定対象金額と同額）
            </td>
          </tr>
          <tr>
            <td>キャンセル料の方が高い</td>
            <td>キャンセル料と同額</td>
          </tr>
          <tr>
            <td>キャンセル前の販売手数料算定対象金額の方が高い</td>
            <td>キャンセル前の販売手数料算定対象金額と同額</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <div class="text-caption ml-15">
      販売手数料は、キャンセル後の販売手数料算定対象金額に対し、「利用料及びその他費用について（利用料ルール）」に定める販売手数料の算定式に基づいて計算されます。
    </div>
  </div>
  <div class="text-body-2 ma-10">
    ■	キャンセル料<br/>
    ①	金額<br/>
    キャンセル料については、顧客都合又はパートナー都合いずれの場合も下請法の主旨に基づき下記（１）（２）の金額の合計金額とします。<br/>
    （１）	キャンセル前の委託料等<br/>
    （２）	キャンセルに伴う返品・廃棄及び手数料等の諸費用<br/>
    ただし、パートナー自らの判断により、キャンセル料が（１）（２）の金額の合計金額を下回る場合があることを妨げるものではありません。<br/>
    <br/>
    ②	支払方法<br/>
    キャンセル料の支払方法は、委託料等の支払方法に準じます。支払いは委託料等と合算して行われます。<br/>
  </div>

  <div class="text-h6 ma-5">
    ３	損害賠償請求、紛争解決など
  </div>

  <div class="text-body-2 ma-10">
    個別契約の変更又はキャンセルに伴う損害賠償請求等を含む費用負担については、個別契約の変更又はキャンセルの都度、顧客とパートナー間の合意によりこれを定めます。<br/>
    <br/>
    その他、個別契約の変更又はキャンセルに関する紛争は、契約当事者間で解決して下さい。<br/>
    ミスミは、本プラットフォーム上でミスミが責任を負う旨を明示した場合を除き、個別契約の変更又はキャンセルに関する紛争解決及び損害を賠償する責任は負いません。<br/>
  </div>

  <div class="text-body-2 ma-10" align="right">
    以上<br/>
    制定日：2021年8月12日<br/>
    改定日：2024年6月24日
  </div>

  <v-divider></v-divider>

  <v-card-title class="text-h5 ma-5">
    ミスミの商標使用について（ミスミ商標使用ルール）
  </v-card-title>
  <div class="text-body-2 ma-10">
    パートナーは、以下の定めに従い、ミスミの商標（以下、ロゴ）を使用することができます。<br/>
    １．	パートナーは、ミスミが使用許諾するロゴのデータ（以下、「ロゴデータ」）を、本プラットフォームのプロモーション又はパートナーが本プラットフォームを介して提供する本製品等のプロモーションを行う目的（以下、本目的といいます）で、別途定めるガイドラインに基づき利用することができます。本目的及びガイドラインに定める掲載媒体、掲載方法等の使用条件の範囲を超えて使用、複製、編集又は加工（以下、「使用等」）できません。<br/>
    ２．	パートナーは、ロゴデータを次の各号に定める第三者に開示し、又は使用させることができるものとします。但し、当該第三者がmeviy partnersパートナー利用規約第３１条、本利用ルール及び別途定めるガイドラインと同等の義務を負うことを承諾した場合に限ります。<br/>
    (1)	本プラットフォームのプロモーションを行う媒体の製作委託先<br/>
    (2)	本プラットフォームのプロモーションの委託先<br/>
    ３．	パートナーは、ロゴデータが不当に使用等又は開示等されないよう適切に管理します。<br/>
    ４．	パートナーは、ミスミがロゴデータの取り扱い、変更又は使用中止を別途指示した場合、遅延なくその指示に従います。<br/>
    ５．	ミスミのロゴの使用料は無償です。ただし、ロゴ使用にかかる費用についてはパートナーの負担となります。<br/>
    ６．	本規約第４０条に定める利用終了後は、パートナーはロゴの使用はできません。<br/>
    ７．	パートナーが本ルールに違反しミスミに損害が生じた場合、パートナーは直ちに損害の拡大防止措置を取ると共に、ミスミが被った損害を賠償します。<br/>
    ８．	上記以外の事項については、パートナーとミスミの協議により決定します。<br/>
  </div>
  <div class="text-body-2 ma-10" align="right">
    以上<br/>
    制定日：2021年10月4日<br/>
    改定日：2024年6月24日
  </div>

</v-card>
</template>
<script>
export default {
  name: 'FeePayment',
}
</script>