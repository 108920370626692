<template>
  <v-container>
    <v-card v-if="form.isviewform" class="mb-5" flat>
      <v-card-title>担当者情報</v-card-title>
    </v-card>
    <v-alert dense outlined type="warning" class="mb-10 text-subtitle-2" color="blue-grey darken-2">
      下記で登録頂く情報をもとに、管理者ユーザーとしてmeivy partnersのアカウントを作成します。<br/>
      管理者ユーザーは、meviy partnersサイト上で他のユーザを作成することが可能です。
    </v-alert>
    <validation-observer
      ref="observer"
      v-slot="{ invalid }"
    >
      <form @submit.prevent="submit">
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="担当者名 姓"
              rules="required|kanji|max:5"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_person_name_sei_kanji"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="担当者名 姓"
                placeholder="例）三住"
                hint="5文字まで"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="担当者名 名"
              rules="required|kanji|max:5"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_person_name_mei_kanji"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="担当者名 名"
                placeholder="例）次郎"
                hint="5文字まで"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="担当者名 姓（カナ）"
              rules="required|kanaall|max:10|kanamax:10"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_person_name_sei_kana"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="担当者名 姓（カナ）"
                placeholder="例）ミスミ"
                hint="カタカナ10文字まで。濁点も1文字としてカウントされます"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="担当者名 名（カナ）"
              rules="required|kanaall|max:10|kanamax:10"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_person_name_mei_kana"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="担当者名 名（カナ）"
                placeholder="例）ジロウ"
                hint="カタカナ10文字まで。濁点も1文字としてカウントされます"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="grey" outlined small>任意</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="部課名"
              rules="kanji|max:15"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_buka_name_kanji"
                :readonly="!inputable"
                :error-messages="errors"
                label="部課名"
                placeholder="例）事業開発部"
                hint="15文字まで"
              ></v-text-field>
            </validation-provider>
            </v-col>
          <v-col cols="1" align="right">
            <v-chip color="grey" outlined small>任意</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
              v-slot="{ errors }"
              name="部課名（カナ）"
              rules="kanaall|max:30|kanamax:30"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_buka_name_kana"
                :readonly="!inputable"
                :error-messages="errors"
                label="部課名（カナ）"
                placeholder="例）ジギョウカイハツブ"
                hint="カタカナ30文字まで。濁点も1文字としてカウントされます"
              ></v-text-field>
            </validation-provider>
            </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
                v-slot="{ errors }"
                name="担当者TEL"
                rules="required|telfaxjp"
            >
              <v-text-field
                  persistent-placeholder persistent-hint outlined
                  v-model.trim="c_person_tel"
                  :readonly="!inputable"
                  :error-messages="errors"
                  required
                  label="担当者TEL"
                  placeholder="例）03-5805-7050"
                  hint="半角数字とハイフン記号（-）"
              ></v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="1" align="right">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="5">
            <validation-provider
                v-slot="{ errors }"
                name="担当者FAX"
                rules="required|telfaxjp"
            >

              <v-text-field
                  persistent-placeholder persistent-hint outlined
                  v-model.trim="c_person_fax"
                  :readonly="!inputable"
                  :error-messages="errors"
                  required
                  label="担当者FAX"
                  placeholder="例）03-5805-7050"
                  hint="半角数字とハイフン記号（-）"
              ></v-text-field>
            </validation-provider>
          </v-col>
        </v-row>

        <v-row>

          <v-col cols="1" align="right" class="mb-0 pb-0">
            <v-chip color="red" dark small>必須</v-chip>
          </v-col>
          <v-col cols="6" class="mb-0 pb-0">
            <validation-provider
              v-slot="{ errors }"
              name="メールアドレス"
              rules="required|email"
              vid="personemail"
            >
              <v-text-field
                persistent-placeholder persistent-hint outlined
                v-model.trim="c_person_email"
                :readonly="!inputable"
                :error-messages="errors"
                required
                label="メールアドレス"
                placeholder="例）taro@misumi.co.jp"
              ></v-text-field>

            </validation-provider>
            </v-col>
            <v-col cols="5" class="fill-height">
              <v-alert dense outlined class="text-caption" color="deep-orange">
                こちらがmeviy partners ログインIDとなります。<br />
                登録後はログインIDの変更はできません。
              </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="1" align="right" class="mt-0 pt-0">
              <v-chip color="red" dark small>必須</v-chip>
            </v-col>
            <v-col cols="6" class="mt-0 pt-0">
              <validation-provider
                  v-slot="{ errors }"
                  name="メールアドレス（確認）"
                  rules="required|confirmed:personemail"
              >
                <v-text-field
                    persistent-placeholder persistent-hint outlined
                    v-model.trim="c_person_email_confirm"
                    :readonly="!inputable"
                    :error-messages="errors"
                    required
                    label="メールアドレス（確認）"
                    placeholder="例）taro@misumi.co.jp"
                    hint="確認のため、再度ご入力ください"
                ></v-text-field>
              </validation-provider>
            </v-col>
        </v-row>
          <v-col align="center">
            <v-btn v-if="!form.isviewform" elevation="0" color="white" class="mr-4" @click="$emit('back')">戻る</v-btn>
          <v-btn
            class="mr-4"
            color="blue darken-4"
            outlined
            type="submit"
            :disabled="invalid"
            x-large
          >
            {{ nextbtntext() }}
          </v-btn>
          </v-col>

      </form>
    </validation-observer>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import * as ValidationCustomRules from '@/plugins/vee-validate'
for (const rule in ValidationCustomRules) {
  extend(rule, ValidationCustomRules[rule])
}
import '@mdi/font/css/materialdesignicons.css'
setInteractionMode('eager')

export default {
  name: 'Registry',
  title: '担当者情報',
  data: () => ({
    c_person_name_sei_kanji: String,
    c_person_name_mei_kanji: String,
    c_person_name_sei_kana: String,
    c_person_name_mei_kana: String,
    c_buka_name_kanji: String,
    c_buka_name_kana: String,
    c_person_tel: String,
    c_person_fax: String,
    c_person_email: String,
    c_person_email_confirm: String,
  }),

  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: PartnerForm,
    inputable: Boolean,
    person_name_sei_kanji: String,
    person_name_mei_kanji: String,
    person_name_sei_kana: String,
    person_name_mei_kana: String,
    buka_name_kanji: String,
    buka_name_kana: String,
    person_tel: String,
    person_fax: String,
    person_email: String,
    person_email_confirm: String,
  },
  computed: {
  },
  methods: {
    submit () {
      this.$refs.observer.validate()
      this.$emit('update:validation_person', true)
      this.$emit('update:person_name_sei_kanji', this.c_person_name_sei_kanji)
      this.$emit('update:person_name_mei_kanji', this.c_person_name_mei_kanji)
      this.$emit('update:person_name_sei_kana', ValidationCustomRules.hiraToKana(this.c_person_name_sei_kana))
      this.$emit('update:person_name_mei_kana', ValidationCustomRules.hiraToKana(this.c_person_name_mei_kana))
      this.$emit('update:buka_name_kanji', this.c_buka_name_kanji)
      this.$emit('update:buka_name_kana', ValidationCustomRules.hiraToKana(this.c_buka_name_kana))

      this.$emit('update:person_tel', this.c_person_tel)
      this.$emit('update:person_fax', this.c_person_fax)
      this.$emit('update:person_email', this.c_person_email)
      this.$emit('update:person_email_confirm', this.c_person_email_confirm)

      if(this.form.id) {
        this.$emit('confirm')
      } else {
        this.$emit('next')
      }
    },
    nextbtntext() {
      switch (this.form.isviewform) {
        case true:
          return "確認"
        default:
          return "次へ"
      }
    },
  },
  async created () {
    this.c_person_name_sei_kanji = this.person_name_sei_kanji
    this.c_person_name_mei_kanji = this.person_name_mei_kanji
    this.c_person_name_sei_kana = this.person_name_sei_kana
    this.c_person_name_mei_kana = this.person_name_mei_kana
    this.c_buka_name_kanji = this.buka_name_kanji
    this.c_buka_name_kana = this.buka_name_kana
    this.c_person_tel = this.person_tel
    this.c_person_fax = this.person_fax
    this.c_person_email = this.person_email
    this.c_person_email_confirm = this.person_email_confirm
  },
  async mounted(){
    this.$nextTick(function () {
      if (this.form.isviewform == null) {
        switch (this.$route.name) {
          case 'ViewInfo':
            this.$router.push(this.$route.path);
            break
          case 'Registry':
            this.$router.push({hash: '#supplier'})
            break
        }
      } else {
        if (!this.form.isviewform && this.form.id) { // 登録フローかつ登録完了している
          this.$router.push('/viewlist')
        } else if (!this.form.isviewform && !this.form.validation_supplier) { // 登録フローかつ前のステップが欠損している
          this.$router.push({hash: '#supplier'})
        }
      }
    })
  },
}
</script>
