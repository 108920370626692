var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.form.isviewform)?_c('v-card',{staticClass:"mb-5",attrs:{"flat":""}},[_c('v-card-title',[_vm._v("担当者情報")])],1):_vm._e(),_c('v-alert',{staticClass:"mb-10 text-subtitle-2",attrs:{"dense":"","outlined":"","type":"warning","color":"blue-grey darken-2"}},[_vm._v(" 下記で登録頂く情報をもとに、管理者ユーザーとしてmeivy partnersのアカウントを作成します。"),_c('br'),_vm._v(" 管理者ユーザーは、meviy partnersサイト上で他のユーザを作成することが可能です。 ")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 姓","rules":"required|kanji|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 姓","placeholder":"例）三住","hint":"5文字まで"},model:{value:(_vm.c_person_name_sei_kanji),callback:function ($$v) {_vm.c_person_name_sei_kanji=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_name_sei_kanji"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 名","rules":"required|kanji|max:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 名","placeholder":"例）次郎","hint":"5文字まで"},model:{value:(_vm.c_person_name_mei_kanji),callback:function ($$v) {_vm.c_person_name_mei_kanji=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_name_mei_kanji"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 姓（カナ）","rules":"required|kanaall|max:10|kanamax:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 姓（カナ）","placeholder":"例）ミスミ","hint":"カタカナ10文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_person_name_sei_kana),callback:function ($$v) {_vm.c_person_name_sei_kana=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_name_sei_kana"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者名 名（カナ）","rules":"required|kanaall|max:10|kanamax:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者名 名（カナ）","placeholder":"例）ジロウ","hint":"カタカナ10文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_person_name_mei_kana),callback:function ($$v) {_vm.c_person_name_mei_kana=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_name_mei_kana"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"grey","outlined":"","small":""}},[_vm._v("任意")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"部課名","rules":"kanji|max:15"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"label":"部課名","placeholder":"例）事業開発部","hint":"15文字まで"},model:{value:(_vm.c_buka_name_kanji),callback:function ($$v) {_vm.c_buka_name_kanji=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_buka_name_kanji"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"grey","outlined":"","small":""}},[_vm._v("任意")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"部課名（カナ）","rules":"kanaall|max:30|kanamax:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"label":"部課名（カナ）","placeholder":"例）ジギョウカイハツブ","hint":"カタカナ30文字まで。濁点も1文字としてカウントされます"},model:{value:(_vm.c_buka_name_kana),callback:function ($$v) {_vm.c_buka_name_kana=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_buka_name_kana"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者TEL","rules":"required|telfaxjp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者TEL","placeholder":"例）03-5805-7050","hint":"半角数字とハイフン記号（-）"},model:{value:(_vm.c_person_tel),callback:function ($$v) {_vm.c_person_tel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_tel"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('validation-provider',{attrs:{"name":"担当者FAX","rules":"required|telfaxjp"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"担当者FAX","placeholder":"例）03-5805-7050","hint":"半角数字とハイフン記号（-）"},model:{value:(_vm.c_person_fax),callback:function ($$v) {_vm.c_person_fax=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_fax"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"メールアドレス","rules":"required|email","vid":"personemail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"メールアドレス","placeholder":"例）taro@misumi.co.jp"},model:{value:(_vm.c_person_email),callback:function ($$v) {_vm.c_person_email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"fill-height",attrs:{"cols":"5"}},[_c('v-alert',{staticClass:"text-caption",attrs:{"dense":"","outlined":"","color":"deep-orange"}},[_vm._v(" こちらがmeviy partners ログインIDとなります。"),_c('br'),_vm._v(" 登録後はログインIDの変更はできません。 ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"1","align":"right"}},[_c('v-chip',{attrs:{"color":"red","dark":"","small":""}},[_vm._v("必須")])],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"メールアドレス（確認）","rules":"required|confirmed:personemail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"persistent-placeholder":"","persistent-hint":"","outlined":"","readonly":!_vm.inputable,"error-messages":errors,"required":"","label":"メールアドレス（確認）","placeholder":"例）taro@misumi.co.jp","hint":"確認のため、再度ご入力ください"},model:{value:(_vm.c_person_email_confirm),callback:function ($$v) {_vm.c_person_email_confirm=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"c_person_email_confirm"}})]}}],null,true)})],1)],1),_c('v-col',{attrs:{"align":"center"}},[(!_vm.form.isviewform)?_c('v-btn',{staticClass:"mr-4",attrs:{"elevation":"0","color":"white"},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("戻る")]):_vm._e(),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"blue darken-4","outlined":"","type":"submit","disabled":invalid,"x-large":""}},[_vm._v(" "+_vm._s(_vm.nextbtntext())+" ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }